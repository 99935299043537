var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('assign-customer',{attrs:{"type":"security"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('p',{staticClass:"h3 font-weight-bold text-muted"},[_vm._v("Assign Customer")]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("The customer to assign products to.")])]},proxy:true},{key:"selected",fn:function(ref){
var selected = ref.selected;
return [_c('p',{staticClass:"h3 font-weight-bold text-nowrap text-primary text-truncate"},[_vm._v(_vm._s(selected && selected.company_name))]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(_vm._s(selected && selected.address && selected.address.address_snippet))])]}},{key:"result",fn:function(ref){
var customer = ref.customer;
return [_c('div',{staticClass:"d-flex flex-column flex-fill"},[_c('p',{staticClass:"h5 flex-fill mb-0 text-primary"},[_vm._v(_vm._s(customer.company_name))]),_c('p',{staticClass:"text-muted mb-0"},[_c('small',[_vm._v(_vm._s(customer.company_number)+" - "+_vm._s(customer.address && customer.address.address_snippet))])])])]}}]),model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('assign-site',{attrs:{"customer":_vm.customer,"disabled":!this.customer,"type":"security"},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('p',{staticClass:"h3 font-weight-bold text-muted"},[_vm._v("Assign Site")]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v("The site to assign products to.")])]},proxy:true},{key:"selected",fn:function(ref){
var selected = ref.selected;
return [_c('p',{staticClass:"h3 font-weight-bold text-nowrap text-primary text-truncate"},[_vm._v(_vm._s(selected.address && _vm.site.address.postcode))]),_c('p',{staticClass:"mb-0 text-muted"},[_vm._v(_vm._s(selected && selected.address && selected.address.address_snippet))])]}},{key:"result",fn:function(ref){
var site = ref.site;
return [_c('div',{staticClass:"d-flex flex-column flex-fill"},[_c('p',{staticClass:"h5 flex-fill mb-0 text-primary"},[_vm._v(_vm._s(site.customer.company_name)+" ("+_vm._s(site.address && site.address.postcode)+")")]),_c('p',{staticClass:"text-muted mb-0"},[_c('small',[_vm._v(_vm._s(site.customer.company_number)+" - "+_vm._s(site.address && site.address.address_snippet))])])])]}}]),model:{value:(_vm.site),callback:function ($$v) {_vm.site=$$v},expression:"site"}})],1),_c('b-col',{staticClass:"my-4",attrs:{"cols":"12"}},[_c('b-table',{attrs:{"bordered":"","responsive":"","striped":"","head-variant":"dark","fields":_vm.allocatedFields,"items":_vm.allocatedItems},scopedSlots:_vm._u([{key:"cell(_)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_c('strong',[_vm._v(_vm._s(item.inventory.name))])]),_c('p',{staticClass:"mb-0"},[_c('small',[_vm._v(_vm._s(item.inventory.ean))])])]}},{key:"cell(ass)",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(item.ass))]),(item.ass < item.alloc)?_c('p',{staticClass:"mb-0 text-danger"},[_vm._v("You must assign more of this item.")]):_vm._e()]}}])})],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"EAN"}},[_c('b-form-input',{ref:"ean",attrs:{"disabled":_vm.loading || !_vm.site.id},on:{"keydown":_vm.assignByEan},model:{value:(_vm.ean),callback:function ($$v) {_vm.ean=$$v},expression:"ean"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9"}},[(_vm.error)?_c('p',{staticClass:"text-danger"},[_vm._v("There was an error assigning that EAN to this site. Try again.")]):_vm._e()]),_c('b-col',{attrs:{"cols":"12","md":"3"}},[(_vm.error)?_c('b-button',{attrs:{"block":"","variant":"secondary"},on:{"click":function($event){$event.preventDefault();_vm.loading = false; _vm.error = null; _vm.ean = ''}}},[_vm._v("Reset")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }