<template>
  <router-view />
</template>

<script>
import securityCustomerModule from '../../store/security-customers'
import securitySiteModule from '../../store/security-sites'
import inventoryModule from '../../store/inventory'

export default {
  beforeCreate () {
    if (!this.$store.hasModule('security-customers')) {
      this.$store.registerModule('security-customers', securityCustomerModule)
    } if (!this.$store.hasModule('security-sites')) {
      this.$store.registerModule('security-sites', securitySiteModule)
    } if (!this.$store.hasModule('inventory')) {
      this.$store.registerModule('inventory', inventoryModule)
    }
  },
  destroyed () {
    if (this.$store.hasModule('inventory')) {
      this.$store.unregisterModule('inventory')
    } if (this.$store.hasModule('security-sites')) {
      this.$store.unregisterModule('security-sites')
    } if (this.$store.hasModule('security-customers')) {
      this.$store.unregisterModule('security-customers')
    }
  }
}
</script>
