<template>
  <b-container>
    <b-row>
      <b-col cols="12" md="6">
        <assign-customer type="security" v-model="customer">
          <template #placeholder>
            <p class="h3 font-weight-bold text-muted">Assign Customer</p>
            <p class="mb-0 text-muted">The customer to assign products to.</p>
          </template>

          <template #selected="{ selected }">
            <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selected && selected.company_name }}</p>
            <p class="mb-0 text-muted">{{ selected && selected.address && selected.address.address_snippet }}</p>
          </template>

          <template #result="{ customer }">
            <div class="d-flex flex-column flex-fill">
              <p class="h5 flex-fill mb-0 text-primary">{{ customer.company_name }}</p>
              <p class="text-muted mb-0"><small>{{ customer.company_number }} - {{ customer.address && customer.address.address_snippet }}</small></p>
            </div>
          </template>
        </assign-customer>
      </b-col>
      <b-col cols="12" md="6">
        <assign-site :customer="customer" :disabled="!this.customer" type="security" v-model="site">
          <template #placeholder>
            <p class="h3 font-weight-bold text-muted">Assign Site</p>
            <p class="mb-0 text-muted">The site to assign products to.</p>
          </template>

          <template #selected="{ selected }">
            <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selected.address && site.address.postcode }}</p>
            <p class="mb-0 text-muted">{{ selected && selected.address && selected.address.address_snippet }}</p>
          </template>

          <template #result="{ site }">
            <div class="d-flex flex-column flex-fill">
              <p class="h5 flex-fill mb-0 text-primary">{{ site.customer.company_name }} ({{ site.address && site.address.postcode }})</p>
              <p class="text-muted mb-0"><small>{{ site.customer.company_number }} - {{ site.address && site.address.address_snippet }}</small></p>
            </div>
          </template>
        </assign-site>
      </b-col>
      <b-col class="my-4" cols="12">
        <b-table bordered responsive striped head-variant="dark" :fields="allocatedFields" :items="allocatedItems">
          <template #cell(_)="{ item }">
            <p class="mb-0"><strong>{{ item.inventory.name }}</strong></p>
            <p class="mb-0"><small>{{ item.inventory.ean }}</small></p>
          </template>
          <template #cell(ass)="{ item }">
            <p class="mb-0">{{ item.ass }}</p>
            <p class="mb-0 text-danger" v-if="item.ass < item.alloc">You must assign more of this item.</p>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12">
        <b-form-group label="EAN">
          <b-form-input :disabled="loading || !site.id" ref="ean" @keydown="assignByEan" v-model="ean"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="9">
        <p class="text-danger" v-if="error">There was an error assigning that EAN to this site. Try again.</p>
      </b-col>
      <b-col cols="12" md="3">
        <b-button block @click.prevent="loading = false; error = null; ean = ''" variant="secondary" v-if="error">Reset</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AssignSite from '../../components/AssignSite.vue'
import AssignCustomer from '../../components/AssignCustomer.vue'

export default {
  components: { AssignCustomer, AssignSite },
  data () {
    return {
      allocatedFields: [
        { key: '_', label: 'Product Information' },
        { key: 'alloc', label: 'Allocated' },
        { key: 'ass', label: 'Assigned' }
      ],
      allocatedItems: [],
      customer: {},
      loading: false,
      error: null,
      site: {},
      ean: ''
    }
  },
  methods: {
    assignByEan (evt) {
      if (evt.keyCode === 13) {
        const data = {
          customer_id: this.customer && this.customer.id,
          site_id: this.site && this.site.id,
          ean: this.ean
        }

        this.loading = true
        window.axios.post('/assign-inventory', data).then(({ data }) => {
          this.allocatedItems = data
          this.loading = false
          this.ean = ''
          setTimeout(() => this.$refs.ean.focus(), 100)
        }).catch(error => { this.error = error })
      }
    },
    fetch (customerId, siteId) {
      this.loading = true
      window.axios.get(`/assign-inventory?customer_id=${customerId}&site_id=${siteId}`).then(({ data }) => {
        this.allocatedItems = data
        this.loading = false
      }).catch(error => { this.error = error })
    }
  },
  watch: {
    site (value) {
      this.fetch(value.customer_id, value.id)
    }
  }
}
</script>
