<template>
  <b-card class="mb-4">
    <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
      <div class="flex-fill" v-if="!selected || selected.id !== (value && value.id)">
        <slot name="placeholder"></slot>
      </div>
      <div class="flex-fill mr-2 minw-0" v-if="(selected && selected.id) === (value && value.id)">
        <slot name="selected" :selected="selected"></slot>
      </div>
      <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
        <b-button block :disabled="disabled" class="text-nowrap" variant="secondary" v-b-modal.assign-site>Assign Site</b-button>
      </div>
    </b-card-text>
    <b-modal id="assign-site" size="lg" cancel-title="Close" ok-title="Assign"
      title="Assign Site" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selected"
      @ok="$emit('input', selected)" body-class="p-0">
      <div class="text-center my-4" v-if="searching">
        <b-spinner variant="secondary" />
      </div>
      <b-list-group flush v-if="!searching && results && results.length">
        <b-list-group-item button @click.prevent="selected = site" :class="{'bg-selected': site.id === (selected && selected.id)}" :key="site.id" v-for="site in results">
          <slot name="result" :site="site"></slot>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    customer: Object,
    disabled: Boolean,
    type: String,
    value: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    results () {
      return this.$store.getters[`${this.type}-sites/data`]
    },
    searching () {
      return this.$store.getters[`${this.type}-sites/isLoading`]
    }
  },
  methods: {
    ...mapActions({
      fetch (dispatch, payload) {
        return dispatch(`${this.type}-sites/fetchManySites`, payload)
      }
    })
  },
  watch: {
    customer (value) {
      this.fetch({ customerId: value.id })
    }
  }
}
</script>
