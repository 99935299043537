<template>
  <b-container>
    <b-form @submit.prevent="submit">
      <b-row class="mb-3">
        <b-col cols="12">
          <h1 class="font-weight-bold text-primary">{{ $route.params.id ? 'Update' : 'Create' }} Inventory</h1>
        </b-col>
      </b-row>
      <b-row v-if="isLoading">
        <b-col cols="12">
          <p class="text-center"><b-spinner variant="secondary"></b-spinner></p>
        </b-col>
      </b-row>
      <b-row v-if="!isLoading">
        <b-col cols="12">
          <b-row>
            <b-col cols="12" md="8">
              <b-form-group label="Name" :invalid-feedback="validationInvalidFeedback(errors, 'name')">
                <b-form-input :disabled="isSaving" :state="validationState(errors, 'name')" v-model="name" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="EAN" :invalid-feedback="validationInvalidFeedback(errors, 'ean')">
                <b-form-input :disabled="isSaving" :state="validationState(errors, 'ean')" v-model="ean" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" :md="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS) ? 4 : 3">
              <b-form-group label="Total Quantity" :invalid-feedback="validationInvalidFeedback(errors, 'quantity')">
                <b-form-input :disabled="isSaving" :state="validationState(errors, 'quantity')" type="number" v-model.number="quantity" />
              </b-form-group>
            </b-col>
            <b-col cols="12" :md="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS) ? 4 : 3">
              <b-form-group label="Available Quantity">
                <b-form-input readonly type="number" v-model.number="availableQuantity" />
              </b-form-group>
            </b-col>
            <b-col cols="12" :md="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS) ? 4 : 3">
              <b-form-group label="Assigned Quantity">
                <b-form-input readonly type="number" v-model.number="assignedQuantity" />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3" v-if="!currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)">
              <b-form-group label="Price" :invalid-feedback="validationInvalidFeedback(errors, 'price')" :state="validationState(errors, 'price')">
                <b-input-group prepend="£">
                  <b-form-input :disabled="isSaving" step="0.01" type="number" v-model.number="price" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4" v-if="currentUserCan(permissions.VIEW_INVENTORY_FINANCIALS)">
            <b-col cols="12" md="6">
              <b-form-group label="Cost" :invalid-feedback="validationInvalidFeedback(errors, 'cost')" :state="validationState(errors, 'cost')">
                <b-input-group prepend="£">
                  <b-form-input :disabled="isSaving" step="0.01" type="number" v-model.number="cost" />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Price" :invalid-feedback="validationInvalidFeedback(errors, 'price')" :state="validationState(errors, 'price')">
                <b-input-group prepend="£">
                  <b-form-input :disabled="isSaving" step="0.01" type="number" v-model.number="price" />
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12">
              <b-row>
                <b-col class="mb-2" cols="12" md="8">
                  <h2>Assigned Sites</h2>
                </b-col>
                <b-col cols="12" md="4">
                  <b-button block variant="primary" v-b-modal.add-site>Assign to Site</b-button>
                </b-col>
              </b-row>
              <b-row>
                <b-col class="mt-2" cols="12">
                  <b-table responsive bordered striped head-variant="dark" :fields="['company', 'site', 'quantity', 'actions']" :items="sites">
                    <template v-slot:cell(company)="row">
                      <span class="text-nowrap">{{ get(row.item, 'customer.company_name') }}</span>
                    </template>
                    <template v-slot:cell(site)="row">
                      <span class="text-nowrap">{{ get(row.item, 'address.address_snippet') }}</span>
                    </template>
                    <template v-slot:cell(actions)="row">
                      <b-button variant="primary" size="sm" @click="showEditInventorySite(row.index)" class="mr-2">Edit Inventory Allocation</b-button>
                      <b-button variant="danger" size="sm" @click="removeInventorySite(row.index)" class="mr-2">Remove</b-button>
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-col>
            <b-modal id="add-site" size="lg" cancel-title="Close" :ok-title="addSite.index < -1 ? 'Save' : 'Create'"
              :title="addSite.index < -1 ? 'Edit Inventory assignment for Site' : 'Assign Inventory to Site'" ok-variant="secondary" cancel-variant="dark"
              @ok="addInventorySite" @cancel="cancelInventorySite">
              <b-row>
                <b-col cols="12">
                  <b-card class="mb-2">
                    <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                      <div class="flex-fill" v-if="!selectedCustomer || selectedCustomer.id !== this.customer_id">
                        <p class="h3 font-weight-bold text-muted">Assign a customer</p>
                        <p class="mb-0 text-muted">Inventory must be assigned to a customer.</p>
                      </div>
                      <div class="flex-fill mr-2 minw-0" v-if="(selectedCustomer && selectedCustomer.id) === this.customer_id">
                        <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selectedCustomer.company_name }}</p>
                        <p class="mb-0 text-muted">{{ selectedCustomer.address && selectedCustomer.address.address_snippet }}</p>
                      </div>
                      <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
                        <b-button block class="text-nowrap" variant="secondary" v-b-modal.assign-customer>Assign Customer</b-button>
                      </div>
                    </b-card-text>
                  </b-card>
                  <b-modal id="assign-customer" size="lg" cancel-title="Close" ok-title="Assign"
                    title="Assign Customer" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedCustomer"
                    @ok="customer_id = selectedCustomer.id" body-class="p-0">
                    <div class="p-3 pb-0">
                      <b-form-group class="mb-0" label="Customer Search" label-for="customer-search" label-sr-only>
                        <b-form-input debounce="500" @update="fetchManyCustomers({ query: customerSearchQuery })" id="customer-search" v-model="customerSearchQuery" v-b-popover.hover.top="'Search for customers that have already been added to the system.'" />
                      </b-form-group>
                    </div>
                    <div class="text-center mt-2 mb-4" v-if="isSearching">
                      <b-spinner variant="secondary" />
                    </div>
                    <b-list-group class="border-top" flush v-if="!isSearching && customerSearchResults && customerSearchResults.length">
                      <b-list-group-item button @click.prevent="selectedCustomer = customer" :class="{'bg-selected': customer.id === (selectedCustomer && selectedCustomer.id)}" :key="customer.id" v-for="customer in customerSearchResults">
                        <div class="d-flex flex-column flex-fill">
                          <p class="h5 flex-fill mb-0 text-primary">{{ customer.company_name }}</p>
                          <p class="text-muted mb-0"><small>{{ customer.company_number }} - {{ customer.address && customer.address.address_snippet }}</small></p>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-modal>
                </b-col>
                <b-col cols="12">
                  <b-card class="mb-4">
                    <b-card-text class="d-flex flex-column flex-lg-row justify-space-between">
                      <div class="flex-fill" v-if="!selectedSite || selectedSite.id !== this.site_id">
                        <p class="h3 font-weight-bold text-muted">Assign a site</p>
                        <p class="mb-0 text-muted">Inventory must be assigned to a customer's site.</p>
                      </div>
                      <div class="flex-fill mr-2 minw-0" v-if="(selectedSite && selectedSite.id) === this.site_id">
                        <p class="h3 font-weight-bold text-nowrap text-primary text-truncate">{{ selectedSite.address && selectedSite.address.postcode }}</p>
                        <p class="mb-0 text-muted">{{ selectedSite.address && selectedSite.address.address_snippet }}</p>
                      </div>
                      <div class="align-self-lg-center d-flex flex-column mt-2 mt-lg-0">
                        <b-button block :disabled="!this.customer_id" class="text-nowrap" variant="secondary" v-b-modal.assign-site>Assign Site</b-button>
                      </div>
                    </b-card-text>
                  </b-card>
                  <b-modal id="assign-site" size="lg" cancel-title="Close" ok-title="Assign"
                    title="Assign Site" ok-variant="secondary" cancel-variant="dark" :ok-disabled="!selectedSite"
                    @ok="site_id = selectedSite.id" body-class="p-0">
                    <div class="text-center my-4" v-if="isSearchingSites">
                      <b-spinner variant="secondary" />
                    </div>
                    <b-list-group flush v-if="!isSearchingSites && siteSearchResults && siteSearchResults.length">
                      <b-list-group-item button @click.prevent="selectedSite = site" :class="{'bg-selected': site.id === (selectedSite && selectedSite.id)}" :key="site.id" v-for="site in siteSearchResults">
                        <div class="d-flex flex-column flex-fill">
                          <p class="h5 flex-fill mb-0 text-primary">{{ site.customer.company_name }} ({{ site.address && site.address.postcode }})</p>
                          <p class="text-muted mb-0"><small>{{ site.customer.company_number }} - {{ site.address && site.address.address_snippet }}</small></p>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-modal>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12">
                  <b-form-group label="Quantity Assigned">
                    <b-form-input type="number" v-model="addSite.quantity" />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-modal>
          </b-row>
          <b-row class="mb-4">
            <b-col cols="12" md="4" offset-md="8">
              <b-button block class="mb-3" :disabled="isSaving" type="submit" variant="secondary"><b-spinner small v-if="isSaving"></b-spinner><span v-if="!isSaving">Save</span></b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </b-container>
</template>

<script>
import get from 'lodash/get'
import currentUser from '../../mixins/currentUser'
import validation from '../../mixins/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [currentUser, validation],
  created () {
    if (this.$route.params.id) {
      this.fetchInventory(this.$route.params.id)
    }
  },
  computed: {
    ...mapGetters('security-customers', { customerSearchResults: 'data', isSearching: 'isLoading' }),
    ...mapGetters('security-sites', { siteSearchResults: 'data', isSearchingSites: 'isLoading' }),
    ...mapGetters('inventory', ['errors', 'isLoading', 'isSaving', 'single']),
    assignedQuantity () {
      return this.sites.reduce((aggr, site) => aggr + parseInt(site.quantity), 0)
    },
    availableQuantity () {
      return parseInt(this.quantity) - parseInt(this.assignedQuantity)
    }
  },
  data () {
    return {
      ean: '',
      name: '',
      quantity: 0,
      cost: 0,
      price: 0,
      addSite: {},
      sites: [],
      site_id: 0,
      customer_id: 0,
      selectedSite: null,
      selectedCustomer: null,
      siteSearchQuery: null,
      customerSearchQuery: null
    }
  },
  methods: {
    ...mapActions('security-customers', ['fetchManyCustomers']),
    ...mapActions('security-sites', ['fetchManySites']),
    ...mapActions('inventory', ['fetchInventory', 'saveInventory']),
    get,
    addInventorySite () {
      if (!this.addSite.index) {
        this.sites.push({ ...this.addSite, id: this.site_id, address: this.selectedSite.address, customer: this.selectedCustomer, site: this.selectedSite })
        this.addSite = {}
      } else {
        this.sites[this.addSite.index] = { ...this.addSite, id: this.site_id, address: this.selectedSite.address, customer: this.selectedCustomer, site: this.selectedSite }
        this.addSite = {}
      }
      this.$bvModal.hide('add-site')
    },
    cancelInventorySite () {
      this.addSite = {}
    },
    removeInventorySite (index) {
      this.sites.splice(index, 1)
    },
    showEditInventorySite (index) {
      this.addSite = { ...this.sites[index], index }
      this.selectedCustomer = this.addSite.customer
      this.customer_id = this.addSite.customer.id
      this.selectedSite = this.addSite.site
      this.site_id = this.addSite.id
      this.sites[index] = this.addSite
      this.$bvModal.show('add-site')
    },
    submit () {
      this.saveInventory({
        id: this.$route.params.id,
        quantity: this.quantity,
        name: this.name,
        ean: this.ean,
        cost: this.cost,
        price: this.price,
        sites: this.sites
      }).then(response => {
        this.$notify().notify({
          timeout: 7500,
          variant: 'success',
          title: `Inventory Item ${this.$route.params.id ? 'Updated' : 'Created'} Successfully`,
          text: 'This inventory item has successfully been saved.'
        })

        if (!this.$route.params.id) {
          this.$router.push({ name: 'security.inventory.update', params: { id: response.data.id } })
        }
      }).catch(error => {
        if (error.response.status !== 422) {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: `Error ${this.$route.params.id ? 'Updating' : 'Creating'} Inventory Item`,
            text: `Error for debugging: ${error.message}`
          })
        } else {
          this.$notify().notify({
            timeout: 15000,
            variant: 'danger',
            title: 'Validation Failed',
            text: 'Please fix the errors in the form before resubmitting.'
          })
        }
      })
    }
  },
  watch: {
    customer_id (value) {
      this.fetchManySites({ customerId: value })
    },
    single (value) {
      this.ean = value.ean
      this.name = value.name
      this.cost = value.cost
      this.price = value.price
      this.quantity = value.quantity

      this.sites = value.sites.map(s => ({ ...s, ...s.pivot, site: { address: s.address, id: s.id } }))
    }
  }
}
</script>

<style>

</style>
